import Vue from "vue";
import Vuex from "vuex";
import router from "@/scripts/router";
import {httpError} from "@/scripts/httpError";
import definitions from "@/scripts/definitions";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import caches from "@/scripts/caches";
import track from "@/scripts/track";
import {nextTick} from "@vue/composition-api";
import storage from "@/scripts/storage";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    account: {
      memberSeq: "",
      memberId: "",
      memberName: "",
      memberAuth: "",
      memberType: "",
      memberSnsType: "",
      memberFilePath: "",
      email: "",
      mobile: "",
      mobileAuthFlag: "N",
      omcTeam: "",
      loggedIn: false,
      checked: false,
    },
    passport: {
      checkin: false,
      checked: false,
    },
    confirm: {
      message: "",
      subMessage: "",
      allow: null,
      disallow: null,
      allowTxt: "확인",
      disallowTxt: "취소",
    },
    investor: {
      investorSeq: "",
      investorAuth: "",
      investorReason: "",
      investorNiceAuthFlag: "",
      investorGrade: "",
      checked: false,
    },
    interests: {
      loaded: true,
      sequences: {
        reward: [],
        invest: [],
      }
    },
    joinForm: {
      memberId: "", // email
      corporateName: "",
      ceoName: "",
      corporateNum: "",
      businessNum: "",
      businessTel: "",
      emailRecv: "", // emailRecv, smsRecv는 동일하게 request
      smsRecv: "", // emailRecv, smsRecv는 동일하게 request
      memberType: "", // P: 개인 / I: 개인사업자 / C: 법인
      joinType: "", // homepage, naver 등
      memberName: "",
      mobile: "",
      passwd: "",
      snsId: "",
      snsType: "",
      email: "",
    },
    follows: {
      loaded: true,
      memberSequences: [],
    },
    // observers: {
    //   modal: null
    // },
    sticky: {
      message: "",
      classes: [],
      show: false,
      fade: false,
    },
    swing: {
      message: "",
      timer: null,
      show: false,
      fade: false,
      time: 3500
    },
    keys: {
      refresh: 0,
      profile: 0,
    },
    layout: "",
    loading: false,
    prevPath: null,
    searchKeyword: "",
    classes: {
      ground: [],
      header: [],
      footer: []
    },
    styles: {
      ground: {
        paddingTop: ""
      }
    },
    listeners: {
      onResize: [],
      onScroll: [],
      onKeydown: [],
      onClick: [],
      onPopstate: [],
    },
    customListeners: {
      appleIdSignInOnSuccess: undefined,
      appleIdSignInOnFailure: undefined,
      axiosResponseOnSuccess: undefined,
    }
  },
  mutations: {
    setStickyMessage(state, {message, type}) {
      state.sticky.message = message;
      state.sticky.show = true;
      state.sticky.fade = false;
      state.sticky.classes = [];

      if (type === "danger") {
        state.sticky.classes.push("alert-danger");
      } else if (type === "warning") {
        state.sticky.classes.push("alert-warning");
      }
    },
    setSwingMessage(state, message) {
      const transitionTime = 370;
      const run = () => {
        state.swing.message = message;

        nextTick(() => {
          state.swing.show = true;
          state.swing.fade = false;
          state.swing.timer = setTimeout(() => {
            state.swing.show = false;
            state.swing.timer = setTimeout(() => {
              state.swing.message = "";
            }, transitionTime);
          }, state.swing.time);
        });
      };

      clearTimeout(state.swing.timer);

      if (state.swing.show) {
        state.swing.fade = true;
        state.swing.timer = setTimeout(run, transitionTime / 2);
      } else {
        run();
      }
    },
    setInterestProjSequences(state, payload) {
      state.interests.loaded = false;

      const reset = () => {
        state.interests.loaded = true;
        state.interests.sequences.reward = [];
        state.interests.sequences.invest = [];
      };

      if (state.account.loggedIn) {
        http.get("/api/interests/projects").then(({data}) => {
          reset();

          for (let p of data.body) {
            if (["R", "M"].includes(p.projectType)) {
              state.interests.sequences.reward.push(p.projectSeq);
            } else if (p.projectType === "I") {
              state.interests.sequences.invest.push(p.projectSeq);
            }
          }

          typeof payload === "function" && payload();
        }).catch(httpError(() => {
          store.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      } else {
        reset();
      }
    },
    setJoinForm(state, payload) {
      if (!payload || !Object.keys(payload).length) {
        for (let i in state.joinForm) {
          state.joinForm[i] = "";
        }
        return;
      }

      for (let i in payload) {
        state.joinForm[i] = payload[i];
      }
    },
    closeSwingMessage(state) {
      clearTimeout(state.swing.timer);
      state.swing.message = "";
    },
    closeStickyMessage(state) {
      state.sticky.message = "";
    },
    toggleInterest(state, payload) {
      const onSuccess = () => {
        const registered = !include;
        store.commit("setSwingMessage", registered ? "관심 프로젝트로 등록하였습니다." : "관심 프로젝트에서 삭제하였습니다.");

        if (typeof payload.onSuccess === "function") {
          payload.onSuccess(registered, !!payload.has);
        } else if (payload.refresh) {
          store.commit("refresh");
        }
      };

      const onFail = () => {
        typeof payload.onFail === "function" && payload.onFail();
      };

      if (!state.account.loggedIn) {
        return onFail();
      }

      const idx = state.interests.sequences[payload.projectType].indexOf(payload.projectSeq);
      // const include = payload.include !== undefined ? payload.include : idx > -1;
      const include = idx > -1;
      const urlPath = `/api/${payload.projectType}/projects/${payload.projectSeq}/interest`;

      const postTrack = () => {
        track.post({
          name: include ? `${payload.projectType}ProjectInterestDel` : `${payload.projectType}ProjectInterestAdd`,
          category: payload.projectType === "invest" ? "투자하기" : "후원하기",
          topic: `관심 프로젝트 ${include ? "삭제" : "등록"}`,
          title: payload.projectName,
          urlPath: urlPath,
          type: "api",
          httpMethod: include ? "DELETE" : "PUT",
        });
      };

      if (include) {
        http.delete(urlPath).then(() => {
          store.commit("setInterestProjSequences");
          onSuccess();
          postTrack();
        }).catch(httpError(() => {
          onFail();
          store.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      } else {
        http.put(urlPath).then(() => {
          store.commit("setInterestProjSequences");
          onSuccess();
          postTrack();
        }).catch(httpError((err) => {
          onFail();
          err?.response?.status !== 401 && this.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      }
    },
    toggleFollow(state, payload) {
      const onSuccess = (memberName) => {
        const registered = !include;
        store.commit("setSwingMessage", memberName + "님" + (registered ? "을 팔로우하였습니다." : "의 팔로우를 취소하였습니다."));
        payload.refresh && store.commit("refresh");
      };

      const onFail = () => {
        typeof payload.onFail === "function" && payload.onFail();
      };

      if (!state.account.loggedIn) {
        return onFail();
      }

      const include = state.follows.memberSequences.find(m => m.makerSeq === payload.makerSeq);
      state.follows.loaded = false;

      if (include) {
        http.delete(`/api/channel/follow/${payload.makerSeq}`).then(({data}) => {
          store.commit("setFollows");
          onSuccess(data.body?.memberName);
        }).catch(httpError(() => {
          onFail();
          store.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      } else {
        http.put("/api/channel/follow", {makerSeq: payload.makerSeq}).then(({data}) => {
          store.commit("setFollows");
          onSuccess(data.body?.memberName);
        }).catch(httpError((err) => {
          onFail();
          err?.response?.status !== 401 && store.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      }
    },
    setFollows(state) {
      state.follows.loaded = false;

      const reset = () => {
        state.follows.loaded = true;
        state.follows.memberSequences = [];
      };

      if (state.account.loggedIn) {
        http.get(`/api/channel/follows`).then(({data}) => {
          reset();
          state.follows.memberSequences = data.body;
        }).catch(httpError(() => {
          store.commit("setSwingMessage", definitions.messages.errorCommon);
        }));
      } else {
        reset();
      }
    },
    setAccount(state, obj) {
      if (obj !== null && typeof obj === "object" && obj.memberSeq && Number(obj.memberSeq)) {
        state.account.memberSeq = obj.memberSeq;
        state.account.memberId = obj.memberId;
        state.account.memberName = obj.memberName;
        state.account.memberAuth = obj.memberAuth;
        state.account.memberType = obj.memberType;
        state.account.memberSnsType = obj.memberSnsType;
        state.account.memberFilePath = obj.memberFilePath + obj.memberFileName;
        state.account.email = obj.email;
        state.account.mobile = obj.mobile;
        state.account.mobileAuthFlag = obj.mobileAuthFlag;
        state.account.omcTeam = obj.omcTeam;
        state.account.loggedIn = true;
      } else {
        state.account.memberSeq = "";
        state.account.memberId = "";
        state.account.memberName = "";
        state.account.memberAuth = "";
        state.account.memberType = "";
        state.account.memberSnsType = "";
        state.account.memberFilePath = "";
        state.account.email = "";
        state.account.mobile = "";
        state.account.mobileAuthFlag = "N";
        state.account.omcTeam = "";
        state.account.loggedIn = false;
      }
    },
    checkPassport(state, func) {
      http.get("/api/passport/checkin").then(({data}) => {
        state.passport.checked = true;
        state.passport.checkin = data.code === "200 OK";
        typeof func === "function" && func();
      });
    },
    checkAccount(state, func) {
      const loggedIn = state.account.loggedIn;

      const run = () => {
        loggedIn && !state.account.loggedIn && store.commit("setSwingMessage", "세션 만료로 인해 로그아웃 되었습니다.");
        store.commit("setInterestProjSequences");
        store.commit("checkInvestor");
        typeof func === "function" && func();
      };

      http.get("/api/member/login/session").then(({data}) => {
        state.account.checked = true;
        store.commit("setAccount", data.body);
        run();
      }).catch(httpError(() => {
        state.account.checked = true;
        store.commit("setAccount");
        run();
      }));
    },
    checkInvestor(state, payload) {
      if (!state.account.loggedIn) {
        state.investor.investorSeq = "";
        state.investor.investorAuth = "";
        state.investor.investorReason = "";
        state.investor.investorNiceAuthFlag = false;
        return;
      }

      http.get("/api/investor/auth").then(({data}) => {
        this.commit("setInvestor", {
          investorSeq: data.body.investor.investorSeq,
          investorAuth: data.body.status,
          investorReason: data.body.investor.reason,
          investorNiceAuthFlag: data.body.investor.niceAuthFlag,
          investorGrade: data.body.investorGrade,
        });

        typeof payload === "function" && payload();
      });
    },
    setInvestor(state, payload) {
      if (payload) {
        state.investor.investorSeq = payload.investorSeq;
        state.investor.investorAuth = payload.investorAuth;
        state.investor.investorReason = payload.investorReason;
        state.investor.investorNiceAuthFlag = payload.investorNiceAuthFlag;
        state.investor.investorGrade = payload.investorGrade;
        state.investor.checked = true;
      } else {
        state.investor.investorSeq = "";
        state.investor.investorAuth = "";
        state.investor.investorReason = "";
        state.investor.investorNiceAuthFlag = "";
        state.investor.investorGrade = "";
        state.investor.checked = false;
      }
    },
    saveTempApply(state, form) {
      const applies = store.getters.tempApplies();
      const applyIdx = applies.map(a => a.projectSeq).indexOf(form.projectSeq);

      if (applyIdx > -1) {
        applies[applyIdx] = form;
      } else {
        applies.push(form);
      }

      storage.set("session", "temporaryApplies", JSON.stringify(applies));
    },
    delTempApply(state, projectSeq) {
      const applies = store.getters.tempApplies();
      const applyIdx = applies.map(a => a.projectSeq).indexOf(projectSeq);
      applyIdx > -1 && applies.splice(applyIdx, 1);

      storage.set("session", "temporaryApplies", JSON.stringify(applies));
    },
    openModal(state, payload) {
      if (typeof payload !== "object") {
        console.warn("The payload must be an object.");
        return;
      }

      const query = lib.getRenewed(router.app.$route.query);
      const name = store.getters.componentFileName(payload.name);
      const modals = store.getters.modals();
      const modal = {name};
      const idx = modals.map(m => m.name).indexOf(modal.name);

      idx > -1 && modals.splice(idx, 1);

      if (payload.params) {
        modal.params = payload.params;
      }

      if (payload.preventClose) {
        modal.preventClose = payload.preventClose;
      }

      if (payload.callback) {
        modal.callback = payload.callback;
      }

      if (payload.change && modals.length) {
        modals[modals.length - 1] = modal;
      } else {
        modals.push(modal);
      }

      query.modals = lib.getEncodedString(modals);

      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      payload.routerReplace ? router.replace({query}) : router.push({query});
    },
    replaceModal(state, payload) {
      payload.change = true;
      store.commit("openModal", payload);
    },
    closeModal(state, payload) {
      if (typeof payload !== "object") {
        console.warn("The payload must be an object.");
        return;
      }

      const modals = store.getters.modals();

      if (!modals.length) {
        return;
      }

      const name = store.getters.componentFileName(payload.name);

      if (payload?.name) {
        for (let i in modals) {
          if (modals[i].name === name) {
            modals.splice(Number(i), 1);
            break;
          }
        }
      } else if (payload?.all) {
        modals.splice(0, modals.length);
      } else {
        const idx = modals.length - 1;
        modals.splice(idx, 1);
      }

      const query = Object.assign({}, router.app.$route.query);
      const modal = store.getters.modal(name);

      if (modals.length) {
        query.modals = lib.getEncodedString(modals);
      } else {
        delete query.modals;
      }

      if (typeof payload.onClose === "function") {
        return router.replace({query}).then(() => {
          payload.onClose(modal);
        });
      }

      return router.push({query});
    },
    tuneModal(state, payload) {
      const name = this.getters.componentFileName(payload.component.name);
      const modal = document.querySelector(`.modal[data-name=${name}]`);

      if (!modal) {
        console.error(`The modal(${payload.component.name}) is not exists.`);
        return;
      }

      modal.dataset.size = payload.size;
      payload.noneShadow && modal.classList.add("none-shadow");
    },
    increaseKey(state, {key} = {}) {
      state.keys[key] += 1;
    },
    refresh(state, {noCheckAccount, callback} = {}) {
      const run = () => {
        store.commit("increaseKey", {key: "refresh"});
        typeof callback === "function" && callback();
      };

      caches.resetHttps();

      if (noCheckAccount) {
        return run();
      }

      store.commit("checkAccount", run);
    },
    addListener(state, payload) {
      const componentName = payload[0];
      const action = payload[1];
      const callback = payload[2];

      !state.listeners[action].some(l => l.componentName === componentName)
      && state.listeners[action].push({
        componentName,
        func: callback
      });
    },
    delListener(state, payload) {
      const componentName = payload[0];
      const action = payload[1];

      for (let i in state.listeners[action]) {
        if (state.listeners[action][i].componentName === componentName) {
          return state.listeners[action].splice(i, 1);
        }
      }
    },
    setCustomListener(state, payload) {
      state.customListeners[payload[0]] = payload[1];
    },
    setLayout(state) {
      const path = router.app.$route.path;

      if (path.startsWith("/callback")) {
        state.layout = "callback";
      } else if (path.startsWith("/manage")) {
        state.layout = "manage";
      } else if (path.startsWith("/new-admin") && !path.includes("/firewall")) {
        state.layout = "admin";
      } else if (path.startsWith("/form/") || path.startsWith("/forms/")) {
        state.layout = "form";
      } else if (path.startsWith("/archive/") || path.startsWith("/archives/")) {
        state.layout = "archive";
      } else if (path.includes("/firewall") || ["/passport", "/error", "/landings", "/popup"].some(
          p => path.startsWith(p))) {
        state.layout = "empty";
      } else {
        state.layout = "ground";
      }
    },
    setLoading(state, payload) {
      state.loading = !!payload;
    },
    setCodes(state, {key, value}) {
      state.codes[key] = value;
    },
    setSearchKeyword(state, payload) {
      state.searchKeyword = payload;
    },
    setPrevPath(state, payload) {
      state.prevPath = payload;
    },
    confirm(state, payload) {
      for (let i in payload) {
        if (!(i in state.confirm)) {
          console.warn(`The key(${i}) is wrong.`);
          return;
        }
      }

      state.confirm.message = payload.message;
      state.confirm.subMessage = payload.subMessage;
      state.confirm.allow = payload.allow;
      state.confirm.disallow = payload.disallow;
      state.confirm.allowTxt = payload.allowTxt || "확인";
      state.confirm.disallowTxt = payload.disallowTxt || "취소";
    },
    addComponentClass(state, payload) {
      !state.classes[payload.to].includes(payload.value) && state.classes[payload.to].push(payload.value);
    },
    removeComponentClass(state, payload) {
      const idx = state.classes[payload.to].findIndex(c => c === payload.value);
      idx >= 0 && state.classes[payload.to].splice(idx, 1);
    },
    setComponentStyle(state, payload) {
      state.styles[payload.to][payload.key] = payload.value;
    },
    setImagePopupListener(state, {$parents, destroy, title, url}) {
      if (!$parents || !$parents?.length || !lib.isMobile()) {
        return;
      }

      const $images = [];

      for (let i = 0; i < $parents?.length; i += 1) {
        for (let j = 0; j < $parents[i]?.querySelectorAll("img").length; j += 1) {
          const $imgParentElem = $parents[i]?.querySelectorAll("img")[j].parentElement;

          if ($imgParentElem.tagName?.toLowerCase() === "a" || $imgParentElem.getAttribute("href")) {
            continue;
          }

          $images.push($parents[i]?.querySelectorAll("img")[j]);
        }
      }

      for (let i = 0; i < $images.length; i += 1) {
        const onClick = () => {
          let paths = [];

          for (let j = 0; j < $images.length; j += 1) {
            paths.push(`image${j}=${$images[j]?.attributes?.src?.value}`);
          }

          window.open(`/popup/slides?idx=${i}&title=${window.encodeURI(title)}&url=${url}&${paths.join("&")}`, "",
              "noopener,noreferrer");
        };

        destroy
            ? $images[i].removeEventListener("click", onClick)
            : $images[i].addEventListener("click", onClick);
      }
    },
    async setPopEvent(state,
        {eventId, $parent, redirectUrl, countTitle, bgUrl, eventBtnUrl, normal, rare, destroy, rareExist, isFalling}) {
      if (destroy) {
        const $eventBtnElem = document.getElementById(`popEventBtn${eventId}`);
        $eventBtnElem && $eventBtnElem?.removeEventListener("click", onClick);
        $eventBtnElem && $parent?.removeChild($eventBtnElem);
        return;
      }

      const eventBtnStyle = {
        cursor: "pointer",
        width: "180px",
        height: "180px",
        position: "fixed",
        top: lib.getHeaderHeight(true) + 60 + "px",
        right: "70px",
        backgroundImage: `url(${eventBtnUrl})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };

      if (lib.isMobile()) {
        eventBtnStyle.width = "145px";
        eventBtnStyle.height = "145px";
        eventBtnStyle.right = "5px";
      }

      const $eventBtn = document.createElement("div");
      $eventBtn.id = `popEventBtn${eventId}`;
      $eventBtn.classList.add("hover-scale-up");
      Object.assign($eventBtn.style, eventBtnStyle);
      $parent.appendChild($eventBtn);

      const onClick = async () => {
        // if (!store.state.account.loggedIn) {
        //   return store.dispatch("goLoginPage");
        // }

        if (store.state.account.loggedIn) {
          const checkParticipant = await http.get(`/api/events/${eventId}/check-participation`);

          if (checkParticipant.data.body) {
            return store.commit("setSwingMessage", "이미 이벤트에 참여하셨습니다.");
          }
        }

        store.commit("openModal", {
          name: "EventHitAndHide",
          params: {
            bgUrl,
            eventId,
            countTitle,
            normal,
            rare,
            redirectUrl,
            rareExist,
            isFalling,
          }
        });
      };

      $eventBtn.addEventListener("click", onClick);
    },
  },
  actions: {
    link(context, e) {
      const href = e?.target?.href;
      console.log(e);
      console.log(href);
    },
    previewFile({commit}, file) {
      if (file.fileSeq) {
        const path = file.filePath + file.fileSaveName;
        const pathArr = path.split(".");
        const ext = pathArr[pathArr.length - 1]?.toLowerCase();

        if (["jpg", "jpeg", "png", "gif", "bmp"].includes(ext)) {
          commit("openModal", {
            name: "Preview",
            params: {
              src: path
            }
          });
        } else {
          window.open(path, "");
        }
      } else {
        if (!file.type.startsWith("image/")) {
          return commit("setSwingMessage", "이 파일은 저장 후 미리보기가 가능합니다.");
        }

        commit("openModal", {
          name: "Preview",
          params: {
            src: URL.createObjectURL(file)
          }
        });
      }
    },
    moveBack({state}, path) {
      if (!path || state.prevPath === path) {
        history.back();
        return;
      }

      return router.push({path});
    },
    openLoginModalIfNotLoggedIn({state}) {
      !state.account.loggedIn && store.dispatch("goLoginPage");
    },
    popup(context, {url, target, onClose}) {
      const popup = window.open(url, target, definitions.popup.options);
      storage.remove("popup");

      if (typeof onClose === "function") {
        const interval = setInterval(() => {
          if (popup.closed) {
            clearInterval(interval);
            const data = storage.get("local", "popup");
            storage.remove("local", "popup");
            onClose(lib.isJsonString(data) ? JSON.parse(data) : undefined);
          }
        }, 250);
      }
    },
    appendLink(context, {href, onload}) {
      if (document.querySelector(`link[href="${href}"]`)) {
        return;
      }

      const linkTag = document.createElement("link");
      linkTag.setAttribute("href", href);
      linkTag.setAttribute("rel", "stylesheet");
      linkTag.setAttribute("type", "text/css");

      document.head.insertBefore(linkTag, document.getElementById("baseCss"));
      // document.head.appendChild(linkTag);

      linkTag.onload = () => {
        typeof onload === "function" && onload();
      };
    },
    appendScript(context, {src, onFirstLoad, onEveryLoad}) {
      let scriptTag = document.querySelector(`script[src="${src}"]`);

      if (scriptTag) {
        if (scriptTag.classList.contains("loaded")) {
          typeof onEveryLoad === "function" && onEveryLoad();
        } else {
          scriptTag.onloadFuncs.push(onFirstLoad);
          scriptTag.onloadFuncs.push(onEveryLoad);
        }
      } else {
        scriptTag = document.createElement("script");
        scriptTag.src = src;
        scriptTag.onloadFuncs = [onFirstLoad, onEveryLoad];
        document.head.insertBefore(scriptTag, document.getElementById("baseJs"));

        scriptTag.onload = () => {
          scriptTag.classList.add("loaded");

          for (let i in scriptTag.onloadFuncs) {
            typeof scriptTag.onloadFuncs[i] === "function" && scriptTag.onloadFuncs[i]();
          }
        };
      }
    },
    redirect(context, callback) {
      let redirectUrl = router.app.$route.query.redirectUrl?.toString();

      if (redirectUrl) {
        redirectUrl = window.decodeURIComponent(redirectUrl);
      } else {
        redirectUrl = "/";
      }

      if (redirectUrl.startsWith("/download")) {
        window.location.replace(redirectUrl);
      } else {
        router.replace(redirectUrl).then(() => {
          typeof callback === "function" && callback();
        });
      }
    },
    initSnsShare() {
      this.dispatch("appendScript", {
        src: "//developers.kakao.com/sdk/js/kakao.min.js",
        onFirstLoad: () => {
          window.Kakao.init(definitions.sns.properties.kakaoAppKeyJavaScript);
        }
      });

      this.dispatch("appendScript", {
        src: "//connect.facebook.net/en_US/sdk.js",
        onFirstLoad: () => {
          window.FB.init({
            appId: definitions.sns.properties.facebookAppId,
            xfbml: true,
            version: definitions.sns.properties.facebookAppVersion
          });
        }
      });
    },
    goPassportPage(context, {redirectUrl} = {}) {
      const urls = ["/passport"];

      if (redirectUrl) {
        urls.push(`?redirectUrl=${redirectUrl}`);
      }

      router.replace(urls.join("")).then();
    },
    goLoginPage(context, action = "login", destUrl = router.app.$route.fullPath || "/") {
      window.location.href = context.getters.loginPageUrl(action, destUrl);
    },
    setDocumentTitle(context, val) {
      document.title = (val ? val + " - " : "") + definitions.texts.title;
    },
    callback(context, payload) {
      const modal = payload?.modal || (payload?.component?.name ? store.getters.modal(payload.component.name) : null);

      if (!modal) {
        console.warn("The payload is not an object or has no modal property.");
        return;
      }

      if (modal?.callback === "refresh") {
        store.commit("refresh", payload.afterRun);
      } else {
        const names = modal?.callback?.split(".");

        if (names?.length) {
          const component = store.getters.component(names[0]);

          if (component) {
            const func = component[names[1]] && names.length > 2
                ? component[names[1]][names[2]]
                : component[names[1]];

            if (typeof func !== "function") {
              console.warn(`${modal.callback} is not a function.`);
              return;
            }

            func(payload.params);
          }
        }

        typeof payload.afterRun === "function" && payload.afterRun();
      }
    },
    logoutAccount(context, payload) {
      store.commit("setLoading", true);

      // http 요청 중인 내용이 있을 수 있어 잠시 대시
      setTimeout(() => {
        const urlPath = "/api/member/logout";

        http.post(urlPath).then(() => {
          store.commit("setLoading", false);
          store.commit("setAccount");
          store.commit("setInvestor");
          store.commit("setInterestProjSequences");
          // store.commit("setFollows");

          caches.resetHttps();
          typeof payload?.afterLogout === "function" && payload.afterLogout();

          if (payload?.home) {
            router.push({path: "/"}, undefined);
          } else {
            store.commit("refresh");
          }

          track.post({
            name: "memberLogout",
            category: "회원",
            topic: "로그아웃 완료",
            urlPath: urlPath,
            type: "api",
            httpMethod: "POST"
          });
        }).catch(httpError(() => {
          this.commit("setLoading", false);
        }));
      }, 1000);
    },
    share({commit}, payload) {
      const platform = payload.platform;
      const url = payload.url || "";
      const imgUrl = payload.imgUrl || "";
      const imgWidth = payload.imgWidth || "";
      const imgHeight = payload.imgHeight || "";
      const title = payload.title?.replace(/<(\/)?([a-zA-Z1-9]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/g, "") || "";
      const desc = payload.desc?.replace(/<(\/)?([a-zA-Z1-9]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/g, "") || "";

      // if (["kakaolink", "band"].includes(platform) && !lib.isMobile()) {
      //   commit("setSwingMessage", "모바일 기기에서 이용가능한 기능입니다. 스마트폰에서 이용해주세요.");
      //   return;
      // }

      switch (platform) {
        case "facebook":
          window.FB.ui({method: "share", href: url});
          return;

        case "twitter": {
          const openUrl = "http://twitter.com/intent/tweet?text=" + title + "&url=" + encodeURIComponent(url);
          const features = "left=0,top=0,width=550,height=550,personalbar=0,toolbar=0,scrollbars=0,resizable=0";
          window.open(openUrl, "intent", features);
          return;
        }

        case "kakaolink":
          lib.isMobile() && commit("setLoading", true);

          window.Kakao.Link.sendDefault({
            objectType: "feed",
            content: {
              title: title,
              description: desc,
              imageUrl: imgUrl,
              imageWidth: imgWidth,
              imageHeight: imgHeight,
              link: {
                mobileWebUrl: url,
                webUrl: url
              }
            },
            fail: () => {
              lib.isMobile() && commit("setLoading", false);
              store.commit("setSwingMessage", definitions.messages.errorInquire);
            },
            success: () => {
              lib.isMobile() && commit("setLoading", false);
              console.log("completed");
            }
          });
          return;

        case "band": {
          const openUrl = "http://www.band.us/plugin/share?body=" + encodeURIComponent(title) + encodeURIComponent(
                  "\r\n")
              + encodeURIComponent(url) + "&route=" + url;
          const features = "left=0,top=0,width=550,height=550,personalbar=0,toolbar=0,scrollbars=0,resizable=0";
          window.open(openUrl, "share_band", features);
          return;
        }
      }

      commit("setSwingMessage", "지원하지 않는 SNS입니다.");
    },
  },
  getters: {
    investProjectAvailable: () => async (page) => {
      const res = await http.get(`/api/invest/available?page=${page}`);

      if (res?.error) {
        return false;
      }

      return res.data.body === "Y";
    },
    thumbnailUrl: () => (path, options = {}) => {
      if (!path) {
        return "";
      } else if (lib.isUrl(path)) {
        return path;
      }

      const arr = [];

      if (!options.origin && definitions.urls.imgCdn) {
        arr.push(definitions.urls.imgCdn);
        arr.push(window.encodeURI(path));

        if (options.share) {
          arr.push(`?h=${definitions.thumbnail.height}`);
          arr.push(`&w=${definitions.thumbnail.width}`);
          arr.push("&fit=crop");
          arr.push(`&${definitions.urls.imgCdnMarkSuffix}`);
        } else if (!options.origin) {
          arr.push("?lossless=1");
          // 썸네일 비율을 4:3으로 수정하면서 가로 길이 생략
          !options?.skipSize && arr.push(`&h=${definitions.thumbnail.height}`);
        }
      }

      if (!arr.length) {
        arr.push(window.location.origin);
        arr.push(window.encodeURI(path));
      }

      return arr.join("");
    },
    modal: (state, getters) => (name) => {
      const modals = getters.modals();
      return modals?.find(m => m.name === getters.componentFileName(name));
    },
    modals: () => () => {
      const modals = router.app.$route.query.modals?.toString();
      return modals ? lib.getDecodedObject(modals) : [];
    },
    modalParams: (state, getters) => ({name}) => {
      return getters.modal(name)?.params;
    },
    profileImageUrl: (state) => () => {
      return `/profile/${state.account.memberSeq}?${state.keys.profile}`;
    },
    component: () => (name) => {
      const getComponent = (children) => {
        for (let i in children) {
          if (children[i]?.component?.name === name) {
            return children[i];
          }

          if (children[i].$children?.length) {
            const component = getComponent(children[i].$children);

            if (component) {
              return component;
            }
          }
        }
      };

      return getComponent(router.app.$children);
    },
    componentFileName: () => (name) => {
      if (!name) {
        return "";
      }

      for (let keyword of ["page", "component", "modal"]) {
        if (name.startsWith(keyword)) {
          return name.replace(keyword, "");
        }
      }

      return name;
    },
    tempApplies: () => () => {
      return JSON.parse(storage.get("session", "temporaryApplies") || "[]");
    },
    isAllowedExtension: () => (target, type) => {
      for (let i = 0; i < target?.files?.length; i += 1) {
        const ext = lib.getFileExtension(target.files[i].name);

        if (type === "image" && definitions.limits.fileExtensions.imgStr.includes(ext)) {
          return true;
        } else if (type === "all" && definitions.limits.fileExtensions.allStr.includes(ext)) {
          return true;
        } else if (type === "eKYC" && definitions.limits.fileExtensions.eKYC.includes(ext)) {
          return true;
        }
      }

      target.value = "";
      store.commit("setSwingMessage", definitions.messages.notAllowedFileExtension);
      return false;
    },
    partnerCardLink: () => (contestSeq, contestType) => {
      if (contestType === "S") {
        return `/partnership/${contestSeq}/mock`;
      }

      // #1181 우리동네 전용관 증권형 선노출 관련
      if (contestSeq?.toString() === "721") {
        return `/partnership/${contestSeq}/invest`;
      }

      return `/partnership/${contestSeq}/${router.app.$route.path.includes("/mocklist") ? "mock" : "reward"}`;
    },
    generateRandomId: () => (digit) => {
      const id = [];
      const generateNum = (range) => window.Math.floor(window.Math.random() * range);
      for (let i = 0; i < digit; i += 1) {
        const num = generateNum(26);

        if (num % 2) {
          const letter = "abcdefghijklmnopqrstuvwxyz".charAt(num);
          id.push(generateNum(2) ? letter.toUpperCase() : letter);
        } else {
          id.push(generateNum(10));
        }
      }

      return id.join("");
    },
    loginPageUrl: () => (action = "login", destUrl = router.app.$route.fullPath || "/") => {
      const redirectUrl = lib.getBase64UrlSafe(
          window.location.origin + `/redirect/login?destUrl=${lib.getBase64UrlSafe(destUrl)}`
      );

      return `${definitions.urls.account}/${action}?redirectUrl=${redirectUrl}`;
    }
  }
});

export default store;